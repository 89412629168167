import {createApp} from "vue";

const receiptCreateApp = document.getElementById('receiptCreateApp');

if(receiptCreateApp)
{
    const receiptCreate = createApp({

        data() {
            return {
                aName: "",
                amount: null,
                aId: "",
                receipts: [],
                totalReceipts: null,
                financialYear: new Date().getFullYear(),
                assessmentYear: new Date().getFullYear() + 1,
                lastPaid: new Date().getFullYear() - 1
            }
        },

        methods: {

            addReceipt()
            {
                event.preventDefault();

                let $typeHeadName = $('#typeHeadName');
                let $mId = $('#mId');

                this.receipts.push(
                    { aName: $typeHeadName.val(), aId: $mId.val(), amount: this.amount}
                );

                this.aName = "";
                this.aId = "";
                this.amount = null;
                $typeHeadName.val("");
                $mId.val("");

                this.setTotalReceipts()

            },

            removeReceipt(rId)
            {
                this.receipts.splice(rId, 1);

            },

            setTotalReceipts()
            {
                return  this.receipts.reduce((acc, item) => acc + item.amount, 0);
            },

            addReceiptName(event)
            {

                let mKeyCode = event.keyCode;

                if(mKeyCode === 13){

                    event.preventDefault();

                    let $typeHeadName = $('#typeHeadName');

                    console.log($typeHeadName.val());
                    console.log(this.amount);

                    if($typeHeadName.val() !== "" && this.amount !== null)
                    {
                        this.addReceipt();
                    }


                }

            },

            setAssessmentYear()
            {
                this.assessmentYear = Number(this.financialYear) + 1;
                this.lastPaid = Number(this.financialYear) - 1;

            }

        }

    });

    receiptCreate.mount('#receiptCreateApp');
}
