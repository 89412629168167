import {createApp} from "vue";

const filingDetailsCreateApp = document.getElementById('filingDetailsCreateApp');

if(filingDetailsCreateApp)
{
    const filingDetails = createApp({
        data() {
            return {
                // income
                grossIncome: null,
                deductions: null,
                netIncome: null,

                // tax payable
                isOptedFor115BAC: "",
                taxPayable: null,
                rebateUS87A: null,
                eduCess: null,
                netTax: null,
                penalInterest: null,
                fee234F: null,
                totalPayable: null,

                // tax paid
                advanceTax: null,
                tds: null,
                SATax: null,
                totalTaxesPaid: null,
                refund: null,
                isToShowDetails: false
            }
        },


        methods: {

            setNetIncome()
            {
                this.netIncome = Math.floor( (this.grossIncome - this.deductions) / 10) * 10;
                this.setTaxPayableDetails();
            },

            setTaxPayableDetails()
            {

                if(this.isOptedFor115BAC === "1")
                {

                    // Tax Payable
                    if(this.netIncome > 1500000)
                    {
                        this.taxPayable = ((this.netIncome-1500000)*0.3+1500000);

                    }else if(this.netIncome > 1200000)
                    {
                        this.taxPayable = ((this.netIncome-1200000)*0.2+90000);

                    }else if(this.netIncome > 900000)
                    {
                        this.taxPayable = (this.netIncome-1000000)*0.15+45000;

                    }else if(this.netIncome > 600000)
                    {
                        this.taxPayable = (this.netIncome-600000)*0.1+15000;

                    }else if(this.netIncome > 300000)
                    {
                        this.taxPayable = (this.netIncome-300000)*0.05;

                    }else {
                        this.taxPayable = 0;
                    }

                }else if(this.isOptedFor115BAC === "0") {

                    // Tax Payable
                    if(this.netIncome > 1000000)
                    {
                        this.taxPayable = ((this.netIncome-1000000)*30/100)+110000;

                    }else if(this.netIncome > 500000)
                    {
                        this.taxPayable = ((this.netIncome-500000)*20/100)+10000;

                    }else if(this.netIncome > 300000)
                    {
                        this.taxPayable = (this.netIncome-300000)*5/100;
                    }else {
                        this.taxPayable = 0;
                    }

                }

                this.rebateUS87A = (this.netIncome < 500000) ? this.taxPayable : 0;

                this.eduCess = Math.round(((this.taxPayable - this.rebateUS87A) * 4) /100);

                this.netTax = (this.taxPayable - this.rebateUS87A) + this.eduCess;

                this.totalPayable = this.netTax + this.penalInterest + this.fee234F;


            },

            setTaxPaid()
            {

                this.totalTaxesPaid = this.advanceTax + this.tds + this.SATax;

                this.refund = Math.ceil((this.totalTaxesPaid - this.totalPayable) / 10) * 10;

            },

            allReset()
            {
                this.grossIncome = null;
                this.deductions = null;
                this.netIncome = null;

                // tax payable
                this.isOptedFor115BAC = "";
                this.taxPayable = null;
                this.rebateUS87A = null;
                this.eduCess = null;
                this.netTax = null;
                this.penalInterest = null;
                this.fee234F = null;
                this.totalPayable = null;

                // tax paid
                this.advanceTax = null;
                this.tds = null;
                this.SATax = null;
                this.totalTaxesPaid = null;
                this.refund = null;
            }



        }

    });

    filingDetails.mount('#filingDetailsCreateApp');



}


/*
*
* above is vue
*
* below is jquery
*
*
* */
// Shorthand for $( document ).ready()
$(function() {

    $('#isToShowDetails').hide();

    $('#typeHeadName').typeahead({
        hint: true,
        highlight: true,
        source: function (query, process) {
            return $.get("/api/getClientsByName/" + query, function (data) {
                return process(data);
            });
        },
        "displayText": function (item) {
            // OMG ugly !!! :p
            if (item.id === undefined) return item;
            return item.name;
        },
        updater: function (item) {
            setAssesseeInfo(item);
            return item;
        }

    });

    function setAssesseeInfo(assessee) {

        $('#isToShowDetails').show();

        $('#mPan').val(assessee.pan);
        $('#mId').val(assessee.id);
        $('#mFatherName').text(assessee.fatherName);
        $('#mCity').text(assessee.city);
        $('#mStatus').text(assessee.status);



    }

});
