
$(function () {

    let pan = $('#pan');

    pan.focusout(function () {

         const isValidPan = validatePAN(pan.val());

         if(isValidPan)
         {
             pan.removeClass('is-invalid');

         }else {

             pan.addClass('is-invalid');

         }

    });

    function validatePAN(panNumber) {
        // Regular expression pattern for PAN validation
        const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

        // Check if the provided PAN number matches the pattern
        return panPattern.test(panNumber);
    }




});
