import DataTable from 'datatables.net-bs5';
import 'datatables.net-fixedheader-bs5';
import 'datatables.net-responsive-bs5';


const filingIndexTable2 = document.getElementById('filingIndexTable');
const assesseeIndexTable2 = document.getElementById('assesseeIndexTable');

if(filingIndexTable2)
{
    let filingIndexTable = new DataTable('#filingIndexTable', {
        pageLength: 100,
        fixedHeader: {
            header: true,
        },
    });

}

if(assesseeIndexTable2)
{
    let assesseeIndexTable = new DataTable('#assesseeIndexTable', {
        pageLength: 100,
        fixedHeader: {
            header: true,
        },
    });

}





let mForm = document.getElementById('form');

if(mForm)
{
    mForm.addEventListener('keypress', function(event) {
        if (event.keyCode === 13) { // Check if Enter key was pressed
            event.preventDefault(); // Prevent form submission
        }
    });

}


